module.exports = [
  {
    "name": "Daniel Moultrie, LMFT - Licensed Marriage and Family Therapist",
    "website": "http://www.danielmoultrie.com/",
    "email": "dm@danielmoultrie.com",
    "city": "Fresno",
    "phone": "559-795-5990",
    "state": "California"
  },
  {
    "name": "Fresno Family Counseling Center",
    "website": "http://fresnostate.edu/kremen/about/centers-projects/ffcc/index.html",
    "email": "fresnofamily@yahoo.com",
    "city": "Fresno",
    "phone": "559-229-3085",
    "state": "California"
  },
  {
    "name": "Olive Leaf Family Therapy",
    "website": "https://www.lovereallyisenough.com/",
    "email": "info@lovereallyisenough.com",
    "city": "Fresno",
    "phone": "559-712-4300",
    "state": "California"
  },
  {
    "name": "Central Valley Family Therapy",
    "website": "https://centralvalleyfamilytherapy.com/",
    "email": "reception@centralvalleyfamilytherapy.com",
    "city": "Fresno",
    "phone": "559-691-6840",
    "state": "California"
  },
  {
    "name": "Private Practice, Licensed Marriage and Family Therapist, Priscilla J. Mucciaccio, LMFT",
    "website": "https://www.soulpurpose.today/",
    "email": "info@soulpurpose.today",
    "city": "Fresno",
    "phone": "559-360-7918",
    "state": "California"
  },
  {
    "name": "Fresno Family Therapy (Fallbrook Office)",
    "website": "https://fresnofamilytherapy.com/contact/",
    "email": "info@fresnofamilytherapy.com",
    "city": "Fresno",
    "phone": "559-795-5990",
    "state": "California"
  },
  {
    "name": "Fig Garden Counseling Center",
    "website": "https://www.figgardencounseling.com/index.html",
    "email": "info@figgardencounseling.com",
    "city": "Fresno",
    "phone": "(559) 977-4492",
    "state": "California"
  },
  {
    "name": "Gina Keller PsyD, LMFT",
    "website": "https://www.psychologytoday.com/intl",
    "email": "jo@psychologytoday.com",
    "city": "Fresno",
    "phone": "559-243-1809",
    "state": "California"
  },
  {
    "name": "Peter Nazaretian, LMFT",
    "website": "https://www.figgardencounseling.com/PeterNazaretianLMFT.en.html",
    "email": "info@figgardencounseling.com",
    "city": "Fresno",
    "phone": "559-221-8874",
    "state": "California"
  },
  {
    "name": "Family Behavioral Health",
    "website": "https://www.counselingfresno.net/",
    "email": "appt@fbhsecure.net",
    "city": "Fresno",
    "phone": "559-432-1088",
    "state": "California"
  },
  {
    "name": "Livewell Behavioral Health Counseling Center",
    "website": "https://livewellbhc.com/",
    "email": "info@livewellbhc.com",
    "city": "Fresno",
    "phone": "559-554-9710",
    "state": "California"
  },
  {
    "name": "Stacey B. Thacker, LMFT",
    "website": "https://roubicekandthacker.com/",
    "email": "kristi@roubicekandthacker.com",
    "city": "Fresno",
    "phone": "559-323-8484",
    "state": "California"
  },
  {
    "name": "St Joseph Counseling Center",
    "website": "https://dioceseoffresno.org/people/st-joseph-counseling-center/",
    "email": "webmaster@dioceseoffresno.org",
    "city": "Fresno",
    "phone": "559-438-1200",
    "state": "California"
  },
  {
    "name": "James Powroznik, LMFT",
    "website": "https://www.jamespowroznik.com/",
    "email": "JamesMFC43539@jamespowroznik.com",
    "city": "Fresno",
    "phone": "559-916-0411",
    "state": "California"
  },
  {
    "name": "Alpha Behavioral Counseling Center",
    "website": "https://www.abccenterinc.com/",
    "email": "vblair@abccenterinc.com",
    "city": "Fresno",
    "phone": "559-412-7799",
    "state": "California"
  },
  {
    "name": "Lacey Horsman LMFT",
    "website": "http://www.laceyhorsman.com/",
    "email": "laceyhorsman@gmail.com",
    "city": "Fresno",
    "phone": "559-824-8403",
    "state": "California"
  },
  {
    "name": "Tami Ramage, LMFT/House Psychiatric Clinic, Inc",
    "website": "https://housepsychiatricclinic.com/",
    "email": "staff@houseclinic.org",
    "city": "Fresno",
    "phone": "559-226-1316",
    "state": "California"
  },
  {
    "name": "North Star Wellness Center",
    "website": "https://www.northstarwellnesscenter.org/",
    "email": "info@northstarfamilycenter.org",
    "city": "Fresno",
    "phone": "559-477-9668",
    "state": "California"
  },
  {
    "name": "Omega Mental Health",
    "website": "https://www.omegamentalhealth.com/",
    "email": "staff@omegamentalhealth.com",
    "city": "Fresno",
    "phone": "559-290-7142",
    "state": "California"
  },
  {
    "name": "Allen Wagner - Marriage and Family Therapist",
    "website": "https://alosangelestherapist.com/",
    "email": "allenwagnerlmft@gmail.com",
    "city": "Los Angeles",
    "phone": "310-429-5310",
    "state": "California"
  },
  {
    "name": "Wendy K. Hammond, MA, Licensed Marriage and Family Therapist",
    "website": "https://www.wendyhammond.com/",
    "email": "wkhammond@gmail.com",
    "city": "Los Angeles",
    "phone": "818-486-6306",
    "state": "California"
  },
  {
    "name": "Los Feliz Marriage and Family Therapy",
    "website": "https://losfelizmarriageandfamilytherapy.godaddysites.com/",
    "email": "kristensuze@gmail.com",
    "city": "Los Angeles",
    "phone": "323-714-4949",
    "state": "California"
  },
  {
    "name": "Maple Moriji, Marriage and Family Therapist",
    "website": "https://www.maplemoriji.com/",
    "email": "info@maplemoriji.com",
    "city": "Los Angeles",
    "phone": "562-270-5129",
    "state": "California"
  },
  {
    "name": "Benjamin Caldwell, PsyD, Licensed Marriage and Family Therapist",
    "website": "https://www.bencaldwell.com/",
    "email": "ben@bencaldwell.com",
    "city": "Los Angeles",
    "phone": "323-246-8823",
    "state": "California"
  },
  {
    "name": "Ker Yang Licensed Marriage and Family Therapist PC",
    "website": "https://www.keryanglmft.com/contact-1",
    "email": "keryanglmft@gmail.com",
    "city": "Los Angeles",
    "phone": "424-352-0114",
    "state": "California"
  },
  {
    "name": "Noushin Z. Verdi, Licensed Marriage and Family Therapist",
    "website": "http://www.drnoushinverdi.com/contact/",
    "email": "therapytoheal@gmail.com",
    "city": "Los Angeles",
    "phone": "323-206-6874",
    "state": "California"
  },
  {
    "name": "Uncharted Healing- Mia Raymond Marriage & Family Therapy, Inc.",
    "website": "https://www.unchartedhealingwithmia.com/",
    "email": "UnchartedHealing.Mia@gmail.com",
    "city": "Los Angeles",
    "phone": "323-391-4772",
    "state": "California"
  },
  {
    "name": "Meridian Counseling: Individual and Family Therapy, INC",
    "website": "https://www.meridian-counseling.com/",
    "email": "info@meridian-counseling.com",
    "city": "Los Angeles",
    "phone": "323-332-9905",
    "state": "California"
  },
  {
    "name": "Root to Rise Therapy",
    "website": "https://rootrisetherapyla.com/about-us",
    "email": "jessica@rootrisetherapyla.com",
    "city": "Los Angeles",
    "phone": "310-721-7338",
    "state": "California"
  },
  {
    "name": "Yvonne Thomas, Ph.D.",
    "website": "https://www.yvonnethomasphd.com/",
    "email": "yvonne@yvonnethomasphd.com",
    "city": "Los Angeles",
    "phone": "310-359-9450",
    "state": "California"
  },
  {
    "name": "Susan Quinn Therapist & Life Coach",
    "website": "https://www.susanquinn.net/",
    "email": "susan@susanquinn.net",
    "city": "Los Angeles",
    "phone": "310-600-3458",
    "state": "California"
  },
  {
    "name": "Fatemeh Farahan, LMFT",
    "website": "https://farahantherapy.com/",
    "email": "info@farahantherapy.com",
    "city": "Los Angeles",
    "phone": "310-962-5935",
    "state": "California"
  },
  {
    "name": "William Cloke, PHD",
    "website": "http://www.billcloke.com/",
    "email": "bill@billcloke.com",
    "city": "Los Angeles",
    "phone": "310-440-0156",
    "state": "California"
  },
  {
    "name": "WILA / Wright Institute Los Angeles",
    "website": "https://wila.org/",
    "email": "info@wila.org",
    "city": "Los Angeles",
    "phone": "424-371-5191",
    "state": "California"
  },
  {
    "name": "Downtown LA Therapist - Shari D Lee MFT",
    "website": "https://sharidlee.com/",
    "email": "shari@sharidlee.com",
    "city": "Los Angeles",
    "phone": "323-682-4177",
    "state": "California"
  },
  {
    "name": "Post Family Counseling",
    "website": "https://postfamilycounseling.com/",
    "email": "michelle@postfamilycounseling.com",
    "city": "Los Angeles",
    "phone": "310-927-5611",
    "state": "California"
  },
  {
    "name": "Triune Therapy Collective",
    "website": "https://www.modernintimacy.com/",
    "email": "info@modernintimacy.com",
    "city": "Los Angeles",
    "phone": "310-933-4088",
    "state": "California"
  },
  {
    "name": "Dr. JoAnne Barge",
    "website": "https://drbarge.com/",
    "email": "info@drbarge.com",
    "city": "Los Angeles",
    "phone": "310-820-6300",
    "state": "California"
  },
  {
    "name": "Take Root Therapy",
    "website": "https://www.losangelesmftherapist.com/",
    "email": "info@takeroottherapy.com",
    "city": "Los Angeles",
    "phone": "323-388-5578",
    "state": "California"
  },
  {
    "name": "Family Service Agency of Burbank",
    "website": "https://familyserviceagencyofburbank.org/",
    "email": "fsaburbank@gmail.com",
    "city": "Los Angeles",
    "phone": "818-845-7671",
    "state": "California"
  },
  {
    "name": "Nadia Alvarez, LMFT",
    "website": "https://www.nadiaalvarezmft.com/",
    "email": "nadia@nadiaalvarezmft.com",
    "city": "Los Angeles",
    "phone": "909-287-1071",
    "state": "California"
  },
  {
    "name": "Craig A. Toonder, MFT",
    "website": "http://www.oaklandcouplescounseling.com/",
    "email": "ctoondermft@gmail.com",
    "city": "Oakland",
    "phone": "510-499-7137",
    "state": "California"
  },
  {
    "name": "Cecilia Froberg, MFT",
    "website": "http://ceciliafroberg.com/",
    "email": "contact@ceciliafroberg.com",
    "city": "Oakland",
    "phone": "510-604-6362",
    "state": "California"
  },
  {
    "name": "Joelle Ehre Therapy",
    "website": "http://www.joelleehre.com/index.html",
    "email": "therapy@joelleehre.com",
    "city": "Oakland",
    "phone": "510-788-0804",
    "state": "California"
  },
  {
    "name": "Liz Tong, MFT - Individual, Adolescent, & Couples Therapy",
    "website": "https://dotcenteroakland.com/therapists",
    "email": "info@dotcenteroakland.com",
    "city": "Oakland",
    "phone": "415-857-5558",
    "state": "California"
  },
  {
    "name": "Michael Y. Simon, LMFT",
    "website": "https://www.practicalhelpforparents.com/",
    "email": "michael@practicalhelpforparents.com",
    "city": "Oakland",
    "phone": "510-225-9169",
    "state": "California"
  },
  {
    "name": "Lauren Go, LMFT",
    "website": "https://sites.google.com/view/laurengomft/home",
    "email": "laurengomft@gmail.com",
    "city": "Oakland",
    "phone": "510-982-6262",
    "state": "California"
  },
  {
    "name": "Joan A. Pezanoski, MFT",
    "website": "https://www.bayareatherapist.org/",
    "email": "joan@bayareatherapist.org",
    "city": "Oakland",
    "phone": "510-625-9850",
    "state": "California"
  },
  {
    "name": "The Bridge Therapy Center",
    "website": "https://bridgecentertherapy.com/?utm_source=googlemybusiness&utm_medium=organic&utm_campaign=Google%20My%20Business",
    "email": "clientservices@bridgecentertherapy.com",
    "city": "Oakland",
    "phone": "510-343-7115",
    "state": "California"
  },
  {
    "name": "Interracial Couples Counseling",
    "website": "https://www.interracialcouplescounseling.com/",
    "email": "jewel@interracialcouplescounseling.com",
    "city": "Oakland",
    "phone": "510-320-3150",
    "state": "California"
  },
  {
    "name": "Mey Saephan",
    "website": "http://lastingaffection.com/",
    "email": "lastingaffection@yahoo.com",
    "city": "Oakland",
    "phone": "510-628-0740",
    "state": "California"
  },
  {
    "name": "Dr. Vernita Marsh & Associates",
    "website": "https://www.drvernitamarsh.com/",
    "email": "info@drvernitamarsh.com",
    "city": "Oakland",
    "phone": "510-420-4555",
    "state": "California"
  },
  {
    "name": "Queer Couples Center Oakland",
    "website": "https://queercouplescenter.com/",
    "email": "info@queercouplescenter.com",
    "city": "Oakland",
    "phone": "415-841-2243",
    "state": "California"
  },
  {
    "name": "Phoenix Jackson Family Therapy & Consultation Inc",
    "website": "https://phoenixjacksontherapy.com/#intro",
    "email": "phoenixjacksonmft@gmail.com",
    "city": "Oakland",
    "phone": "510-214-6762",
    "state": "California"
  },
  {
    "name": "Jackie Brookman Mft",
    "website": "https://www.jackiebrookman.com/",
    "email": "jackiebrookmanmft@gmail.com  ",
    "city": "Oakland",
    "phone": "510-433-1121",
    "state": "California"
  },
  {
    "name": "Astraea Bella, LCSW",
    "website": "http://www.astraeabella.com/",
    "email": "therapy@astraeabella.com",
    "city": "Oakland",
    "phone": "510-290-8303",
    "state": "California"
  },
  {
    "name": "Rose P Phelps, MFT",
    "website": "https://www.rosephelpsmarriagefamilytherapist.com/",
    "email": "rosepmft@hotmail.com",
    "city": "Oakland",
    "phone": "510-658-4344",
    "state": "California"
  },
  {
    "name": "Eugene Porter, MFT",
    "website": "http://eugeneportermft.com/contact.html",
    "email": "eugene@eugeneportermft.com",
    "city": "Oakland",
    "phone": "510-652-4213",
    "state": "California"
  },
  {
    "name": "Karen Sprinkel, Individual & Family Trauma Therapy Corporation",
    "website": "https://karensprinkel.com/",
    "email": "karen@karensprinkel.com",
    "city": "Oakland",
    "phone": "510-918-8424",
    "state": "California"
  },
  {
    "name": "Megan Gredesky",
    "website": "https://www.megangredesky.com/",
    "email": "mgredesky@gmail.com",
    "city": "Oakland",
    "phone": "510-556-3699",
    "state": "California"
  },
  {
    "name": "Jerrica Danhoff Psychotherapy",
    "website": "https://www.jerricadanhoff.com/",
    "email": "jerricadanhoffpsychotherapy@gmail.com",
    "city": "Oakland",
    "phone": "415-894-0103",
    "state": "California"
  },
  {
    "name": "Natasa Nordman, LMFT",
    "website": "https://www.therapybynatasa.com/",
    "email": "natasa@therapybynatasa.com",
    "city": "Oakland",
    "phone": "925-788-3535",
    "state": "California"
  },
  {
    "name": "Annaliese Taylor, LMFT",
    "website": "https://www.annaliesetaylor.com/",
    "email": "ataylor.lmft@annaliesetaylor.com",
    "city": "Oakland",
    "phone": "628-232-3889",
    "state": "California"
  },
  {
    "name": "Nick Wightman, LMFT",
    "website": "http://www.nickwightmantherapy.com/contact/",
    "email": "nickwightman.mft@gmail.com",
    "city": "Oakland",
    "phone": "510-907-9719",
    "state": "California"
  },
  {
    "name": "Danielle Dekker, LMFT",
    "website": "https://emdrinoakland.com/",
    "email": "danielle@emdrinoakland.com",
    "city": "Oakland",
    "phone": "510-269-7047",
    "state": "California"
  },
  {
    "name": "Angela Shaiman, LMFT",
    "website": "https://www.angelashaimanmft.com/",
    "email": "angela@angelashaimanmft.com",
    "city": "Oakland",
    "phone": "510-274-1885",
    "state": "California"
  },
  {
    "name": "Mindy Baba, MFT",
    "website": "https://www.mindybabamft.com/",
    "email": "mindybabalmft@gmail.com",
    "city": "Oakland",
    "phone": "510-325-9450",
    "state": "California"
  },
  {
    "name": "Byrne Nicole J",
    "website": "https://counselingwithnicole.com/",
    "email": "nicole@counselingwithnicole.com",
    "city": "Oakland",
    "phone": "510-698-2254",
    "state": "California"
  },
  {
    "name": "Leslie Nipps Constellations",
    "website": "https://www.conviviumconstellations.com/",
    "email": "leslie@conviviumconstellations.com",
    "city": "Oakland",
    "phone": "925-914-1212",
    "state": "California"
  },
  {
    "name": "Fine Lucy",
    "website": "https://www.lucyfine.com/",
    "email": "lucy@lucyfine.com",
    "city": "Oakland",
    "phone": "415-641-8551",
    "state": "California"
  },
  {
    "name": "Evelynn Hernandez-Brown MFT",
    "website": "https://evelynnhernandezbrown.com/contact/",
    "email": "evelynn@evelynnhernandezbrown.com",
    "city": "Oakland",
    "phone": "510-214-3792",
    "state": "California"
  },
  {
    "name": "Dr. Jayn Rajandran, PsyD, LMFT",
    "website": "https://jaynmft.com/index.html",
    "email": "facebook@jayn.org",
    "city": "Oakland",
    "phone": "415-449-1988",
    "state": "California"
  },
  {
    "name": "Therapy with Jessi",
    "website": "https://www.therapywithjessi.com/",
    "email": "j@therapywithjessi.com",
    "city": "Oakland",
    "phone": "213-986-6939",
    "state": "California"
  },
  {
    "name": "Earth Circles Counseling Center",
    "website": "https://www.earthcirclescenter.com/",
    "email": "ec@earthcirclescenter.com",
    "city": "Oakland",
    "phone": "510-361-0346",
    "state": "California"
  },
  {
    "name": "Jennifer Diamond, MA, LMFT",
    "website": "https://www.jenniferdiamondbayarea.com/",
    "email": "jenniferdiamondbayarea@gmail.com",
    "city": "Oakland",
    "phone": "510-373-9995",
    "state": "California"
  },
  {
    "name": "Grady Fort",
    "website": "http://www.therapywithgrady.com/",
    "email": "grady@therapywithgrady.com",
    "city": "Oakland",
    "phone": "510-373-9995",
    "state": "California"
  },
  {
    "name": "Emily Chandler, LMFT",
    "website": "https://www.emilychandler.net/",
    "email": "emilychandlerlmft@gmail.com",
    "city": "Oakland",
    "phone": "510-912-8559",
    "state": "California"
  },
  {
    "name": "Root Awareness",
    "website": "https://www.rootawareness.com/",
    "email": "info@rootawareness.com",
    "city": "Oakland",
    "phone": "510-556-1282",
    "state": "California"
  },
  {
    "name": "George Vassiliades",
    "website": "http://georgevmft.com/",
    "email": "georgevmft@gmail.com",
    "city": "Oakland",
    "phone": "510-239-3976",
    "state": "California"
  },
  {
    "name": "Cindi Rivera, MFT",
    "website": "https://cindiriveratherapy.com/",
    "email": "criveramft@gmail.com",
    "city": "Oakland",
    "phone": "510-482-4445",
    "state": "California"
  },
  {
    "name": "DOT Center - psychotherapy for individuals, couples, children, and families",
    "website": "https://dotcenteroakland.com/",
    "email": "info@dotcenteroakland.com",
    "city": "Oakland",
    "phone": "510-423-3135",
    "state": "California"
  },
  {
    "name": "Whole Wellness Therapy - Sacramento",
    "website": "https://www.wholewellnesstherapy.com/",
    "email": "isaac@wholewellnesstherapy.com",
    "city": "Sacramento",
    "phone": "916-619-7744",
    "state": "California"
  },
  {
    "name": "Susan Armstrong, Licensed Marriage and Family Therapist",
    "website": "https://www.susanarmstronglmft.com/",
    "email": "link2sla@att.net",
    "city": "Sacramento",
    "phone": "916-230-7192",
    "state": "California"
  },
  {
    "name": "Leslie (Whitten) Baughman, LMFT",
    "website": "http://www.connect-counseling.com/",
    "email": "connectcounseling@gmail.com",
    "city": "Sacramento",
    "phone": "916-850-9387",
    "state": "California"
  },
  {
    "name": "Lauren Lowe Therapy",
    "website": "https://laurenlowetherapy.com/",
    "email": "lauren@laurenlowetherapy.com",
    "city": "Sacramento",
    "phone": "916-565-2112",
    "state": "California"
  },
  {
    "name": "Steve DeBenedetti-Emanuel, LMFT",
    "website": "https://rivercitycounseling.com/",
    "email": "steve@rivercitycounseling.com",
    "city": "Sacramento",
    "phone": "916-919-0218",
    "state": "California"
  },
  {
    "name": "Rio City Counseling",
    "website": "https://riocitycounseling.webs.com/",
    "email": "rio.city.counseling@gmail.com",
    "city": "Sacramento",
    "phone": "916-607-6786",
    "state": "California"
  },
  {
    "name": "Marta H. Schmal, MA",
    "website": "http://www.martaschmal.com/",
    "email": "info@martaschmal.com",
    "city": "Sacramento",
    "phone": "916-595-3047",
    "state": "California"
  },
  {
    "name": "Love Jack D",
    "website": "http://www.jacklove.com/",
    "email": "jack@jacklove.com",
    "city": "Sacramento",
    "phone": "916-929-5765",
    "state": "California"
  },
  {
    "name": "Dalise Gada Therapy - Somatic Therapy in Sacramento",
    "website": "https://www.somatictherapyinsac.com/",
    "email": "dalise@somatictherapyinsac.com",
    "city": "Sacramento",
    "phone": "916-895-9484",
    "state": "California"
  },
  {
    "name": "Hope for Healthy Families Counseling Center (Sacramento Office)",
    "website": "http://hopeforhealthyfamilies.org/",
    "email": "Info@hopeforhealthyfamilies.org",
    "city": "Sacramento",
    "phone": "916-686-9209",
    "state": "California"
  },
  {
    "name": "Barbara J. Evans MA LMFT",
    "website": "https://www.networktherapy.com/BarbaraEvans/",
    "email": "info@networktherapy.com",
    "city": "Sacramento",
    "phone": "916-442-3556",
    "state": "California"
  },
  {
    "name": "Thrive Therapy & Counseling",
    "website": "http://thrivetherapyandcounseling.com/",
    "email": "hello@thrivetherapyandcounseling.com",
    "city": "Sacramento",
    "phone": "916-287-3430",
    "state": "California"
  },
  {
    "name": "Deriggi Margaret H",
    "website": "http://www.margiederiggi.com/",
    "email": "margie@margiederiggi.com",
    "city": "Sacramento",
    "phone": "916-457-3532",
    "state": "California"
  },
  {
    "name": "Sacramento Psychotherapy Services, Inc.",
    "website": "http://www.californiatherapy.com/",
    "email": "help@hover.com",
    "city": "Sacramento",
    "phone": "916-339-7443",
    "state": "California"
  },
  {
    "name": "Middle Way Health Family Counseling, INC",
    "website": "http://middlewayhealth.com/",
    "email": "colleen@middlewayhealth.com",
    "city": "Sacramento",
    "phone": "916-492-9007",
    "state": "California"
  },
  {
    "name": "Soul Wisdom Therapy",
    "website": "http://www.soulwisdomtherapy.com/",
    "email": "debcohen@hotmail.com",
    "city": "Sacramento",
    "phone": "916-491-1216",
    "state": "California"
  },
  {
    "name": "Tom Mathews, MA, MFT",
    "website": "https://tommathewsmft.com/",
    "email": "tommathews@surewest.net",
    "city": "Sacramento",
    "phone": "916-781-9888",
    "state": "California"
  },
  {
    "name": "Russell Tony",
    "website": "https://www.tonyrusselltherapist.com/",
    "email": "tony@tonyrusselltherapist.com",
    "city": "Sacramento",
    "phone": "916-443-1931",
    "state": "California"
  },
  {
    "name": "Tabitha Langenfeld, MFT",
    "website": "https://www.findthepeacewithin.com/",
    "email": "tabitha.huddleston@yahoo.com",
    "city": "Sacramento",
    "phone": "916-670-0851",
    "state": "California"
  },
  {
    "name": "Danielle Greenspan LMFT, LPCC",
    "website": "https://www.daniellegreenspanlmftlpcc.com/",
    "email": "greenspan.danielle@gmail.com",
    "city": "Sacramento",
    "phone": "916-847-7107",
    "state": "California"
  },
  {
    "name": "Shideler Anne",
    "website": "http://www.anneshideler.com/",
    "email": "anneshideler@gmail.com",
    "city": "Sacramento",
    "phone": "916-972-7079",
    "state": "California"
  },
  {
    "name": "Dr. Armando Gonzalez, Licensed Therapist",
    "website": "http://www.armandogonzalez.org.customers.tigertech.net/",
    "email": "therapywitharmando@gmail.com",
    "city": "Sacramento",
    "phone": "916-743-4748",
    "state": "California"
  },
  {
    "name": "Orion Rozsa, M.A",
    "website": "http://orionrozsa.com/",
    "email": "therapy@orionrozsa.com",
    "city": "Sacramento",
    "phone": "916-668-9049",
    "state": "California"
  },
  {
    "name": "Marianna Jakucska, LMFT 84050",
    "website": "https://majapsychotherapy.com/",
    "email": "marianna@majapsychotherapy.com",
    "city": "Sacramento",
    "phone": "916-200-9592",
    "state": "California"
  },
  {
    "name": "Ray Brown, PhD, LMFT",
    "website": "http://www.lmft.info/",
    "email": "418alhambra@gmail.com",
    "city": "Sacramento",
    "phone": "916-492-8530",
    "state": "California"
  },
  {
    "name": "Lyla Tyler Lmft: Tyler Lyla",
    "website": "https://kidcounselingsacramento.com/",
    "email": "lyla@kidcounselingsacramento.com",
    "city": "Sacramento",
    "phone": "916-448-1812",
    "state": "California"
  },
  {
    "name": "Audrie L. Meyer, LMFT",
    "website": "https://www.therapysacramento.net/",
    "email": "audrie@therapysacramento.net",
    "city": "Sacramento",
    "phone": "916-234-0083",
    "state": "California"
  },
  {
    "name": "Jongeward Nancy Mfcc",
    "website": "http://www.nancyjongeward.com/index.html",
    "email": "info@nancyjongeward.com",
    "city": "Sacramento",
    "phone": "916-482-9201",
    "state": "California"
  },
  {
    "name": "Ursula Lewis, LMFT",
    "website": "http://www.mindspringtherapy.com/",
    "email": "ursulalewis@mindspringtherapy.com",
    "city": "Sacramento",
    "phone": "530-303-7013",
    "state": "California"
  },
  {
    "name": "Littman Phillip",
    "website": "http://www.phillittmanlcsw.com/",
    "email": "plittman@surewest.net",
    "city": "Sacramento",
    "phone": "916-451-1900",
    "state": "California"
  },
  {
    "name": "Sara Cole , Marriage and Family Therapist",
    "website": "https://www.saracolemft.com/",
    "email": "sara.colemft@gmail.com",
    "city": "San Diego",
    "phone": "619-316-3171",
    "state": "California"
  },
  {
    "name": "Dr. Debra Jedeikin PsyD LMFT Licensed Marriage Family Child Therapist at Del Mar Family Therapy",
    "website": "https://delmarfamilytherapy.com/",
    "email": "debra@delmarfamilytherapy.com",
    "city": "San Diego",
    "phone": "858-342-6200",
    "state": "California"
  },
  {
    "name": "Rita Cole, Marriage & Family Therapist, Counseling couples and individuals",
    "website": "http://www.ritacolemft.com/",
    "email": "rita@ritacolemft.com",
    "city": "San Diego",
    "phone": "619-517-7974",
    "state": "California"
  },
  {
    "name": "Jodi Staszak, Marriage and Family Therapist",
    "website": "https://www.staszaktherapy.com/",
    "email": "JodiS.MFT@gmail.com",
    "city": "San Diego",
    "phone": "619-818-0375",
    "state": "California"
  },
  {
    "name": "Susannah Muller, Licensed Marriage and Family Therapist",
    "website": "http://www.therapy-sandiego.com/",
    "email": "susannah@therapy-sandiego.com",
    "city": "San Diego",
    "phone": "619-787-2743",
    "state": "California"
  },
  {
    "name": "Maira Horta Marriage & Family Therapy",
    "website": "https://mairahorta.com/",
    "email": "therapy@mairahorta.com",
    "city": "San Diego",
    "phone": "858-218-6255",
    "state": "California"
  },
  {
    "name": "Jen Fisher, Licensed Marriage & Family Therapist",
    "website": "https://www.jenfishertherapy.com/",
    "email": "jenfishertherapy@gmail.com",
    "city": "San Diego",
    "phone": "619-252-7429",
    "state": "California"
  },
  {
    "name": "Wendy Belding Marriage and Family Therapy, inc.",
    "website": "https://wendybelding.com/",
    "email": "wendy@wendybelding.com",
    "city": "San Diego",
    "phone": "619-318-7208",
    "state": "California"
  },
  {
    "name": "Coaching Through Chaos - Dr Colleen Mullen, Psy.D,LMFT",
    "website": "https://coachingthroughchaos.com/",
    "email": "drcolleenmullen@coachingthroughchaos.com",
    "city": "San Diego",
    "phone": "619-881-0051",
    "state": "California"
  },
  {
    "name": "Potentia Family Therapy, Inc.",
    "website": "https://www.potentiatherapy.com/",
    "email": "rebecca@potentiatherapy.com",
    "city": "San Diego",
    "phone": "619-819-0283",
    "state": "California"
  },
  {
    "name": "Jeffrey Young, LMFT",
    "website": "https://www.jeffreyyoungtherapy.com/contact",
    "email": "jeffreyyoungmft@gmail.com",
    "city": "San Diego",
    "phone": "619-535-9755",
    "state": "California"
  },
  {
    "name": "Tatiana M. Moore, LMFT",
    "website": "https://www.tmooretherapy.com/ContactMe.en.html",
    "email": "tatiana@tmooretherapy.com",
    "city": "San Diego",
    "phone": "760-271-2629",
    "state": "California"
  },
  {
    "name": "Katie Miles, LMFT",
    "website": "https://www.katiemileslmft.com/contact",
    "email": "katiemileslmft@gmail.com",
    "city": "San Diego",
    "phone": "915-301-8289",
    "state": "California"
  },
  {
    "name": "Dr. Lisa J. Palmer-Olsen Couple and Family Therapy and Associates",
    "website": "https://drlisapalmerolsen.com/",
    "email": "jensiladi@gmail.com",
    "city": "San Diego",
    "phone": "619-895-0509",
    "state": "California"
  },
  {
    "name": "Matthew H. Koch, LMFT",
    "website": "http://www.alifeofbalancesd.com/index.html",
    "email": "matthew@alifeofbalancesd.com",
    "city": "San Diego",
    "phone": "619-549-3545",
    "state": "California"
  },
  {
    "name": "The Relationship Place Counseling and Therapy",
    "website": "https://www.sdrelationshipplace.com/",
    "email": "info@sdrelationshipplace.com",
    "city": "San Diego",
    "phone": "619-535-8890",
    "state": "California"
  },
  {
    "name": "Tina Mears, MFT",
    "website": "https://tinamearstherapy.com/contact/",
    "email": "tina@cooperativeparentingsd.com",
    "city": "San Diego",
    "phone": "619-261-7409",
    "state": "California"
  },
  {
    "name": "San Diego Institute for Couples",
    "website": "http://www.sdicouples.com/contact",
    "email": "breiswig@sdicouples.com",
    "city": "San Diego",
    "phone": "619-542-1052",
    "state": "California"
  },
  {
    "name": "Harriet Schatz, SF Marriage & Family Therapist",
    "website": "https://www.harrietschatzmft.com/contact.html",
    "email": "harrietschatzmft@aol.com",
    "city": "San Francisco",
    "phone": "415-262-0404",
    "state": "California"
  },
  {
    "name": "Carl Montgomery, Licensed Marriage & Family Therapist (MFT)",
    "website": "http://www.montgomerymft.com/",
    "email": "montgomerymft@gmail.com",
    "city": "San Francisco",
    "phone": "415-255-2525",
    "state": "California"
  },
  {
    "name": "Aaron Hagaman, Marriage and Family Therapist",
    "website": "https://www.aaronhagaman.com/",
    "email": "aaron@aaronhagaman.com ",
    "city": "San Francisco",
    "phone": "415-580-0136",
    "state": "California"
  },
  {
    "name": "Rainer Geissler, M.A. Marriage & Family Therapist",
    "website": "http://www.rainergeisslerlmft.com/ContactMe.en.html",
    "email": "rainergeissler@comcast.net",
    "city": "San Francisco",
    "phone": "415-999-1049",
    "state": "California"
  },
  {
    "name": "Victoria Storr Therapy",
    "website": "https://www.victoriastorr.com/",
    "email": "vrstorr@gmail.com",
    "city": "San Francisco",
    "phone": "415-610-8724",
    "state": "California"
  },
  {
    "name": "Bonnie Cardell Marriage & Family Therapy",
    "website": "https://www.bonniecardellmft.com/",
    "email": "bonniecardellmft@gmail.com",
    "city": "San Francisco",
    "phone": "415-484-9894",
    "state": "California"
  },
  {
    "name": "Allison Zamani, Associate Marriage and Family Therapist",
    "website": "https://www.allisonzamani.com/",
    "email": "allison@allisonzamani.com",
    "city": "San Francisco",
    "phone": "415-255-2647",
    "state": "California"
  },
  {
    "name": "Christian Counseling Centers San Francisco",
    "website": "http://www.christiancounselingcenters.org/san-francisco/",
    "email": "admin@ccctherapist.org",
    "city": "San Francisco",
    "phone": "415-674-7504",
    "state": "California"
  },
  {
    "name": "Matt Foley, MFT",
    "website": "https://mattfoleymft.com/",
    "email": "mattfoleymft@gmail.com",
    "city": "San Francisco",
    "phone": "510-470-4135",
    "state": "California"
  },
  {
    "name": "Bay Area Family Therapy Services",
    "website": "https://www.bayareafamilytherapyservices.org/",
    "email": "info@BAFTS.org",
    "city": "San Francisco",
    "phone": "415-460-7294",
    "state": "California"
  },
  {
    "name": "Chandrakala Rai, MFT - Marriage and Family Therapist",
    "website": "http://chandrarai.com/",
    "email": "info@chandrarai.com",
    "city": "San Jose",
    "phone": "408-475-2311",
    "state": "California"
  },
  {
    "name": "MARRIAGE & FAMILY THERAPY Sasha Esposito San Roman, MA, MFT",
    "website": "https://www.sashaesposito.com/",
    "email": "sashaesposito@gmail.com ",
    "city": "San Jose",
    "phone": "408-348-3896",
    "state": "California"
  },
  {
    "name": "Roberta Faust, Licensed Marriage and Family Therapist",
    "website": "http://www.fishonwheelstherapy.com/index.html",
    "email": "roberta@fishonwheelstherapy.com",
    "city": "San Jose",
    "phone": "408-410-5029",
    "state": "California"
  },
  {
    "name": "Melinda Carlisle Brackett, Licensed Marriage & Family Therapist",
    "website": "https://criticalpathcounseling.com/contact/",
    "email": "mcarlisle@criticalpathcounseling.com",
    "city": "San Jose",
    "phone": "408-893-4032",
    "state": "California"
  },
  {
    "name": "Amy Powell, Marriage Family Therapist",
    "website": "http://belovetherapy.com/",
    "email": "info@belovetherapy.com",
    "city": "San Jose",
    "phone": "408-369-9800",
    "state": "California"
  },
  {
    "name": "Nugent Family Counseling",
    "website": "https://www.nugenttherapy.com/",
    "email": "Admin@nugenttherapy.com",
    "city": "San Jose",
    "phone": "408-341-9222",
    "state": "California"
  },
  {
    "name": "Family Therapy Center of Silicon Valley",
    "website": "https://www.familytherapycentersv.com/",
    "email": "information@ftcsv.com",
    "city": "San Jose",
    "phone": "408-560-0065",
    "state": "California"
  },
  {
    "name": "Child & Family Counseling Group, Inc.",
    "website": "https://www.childfamilygroup.com/",
    "email": "info@childfamilygroup.com",
    "city": "San Jose",
    "phone": "408-351-1044",
    "state": "California"
  },
  {
    "name": "Yvonne Blockie Marriage Family",
    "website": "http://www.yvonnemft.com/",
    "email": "yvonne@yvonnemft.com",
    "city": "San Jose",
    "phone": "408-235-0872",
    "state": "California"
  },
  {
    "name": "Family Matters Counseling Services",
    "website": "https://familymatters.expert/",
    "email": "kentcampbell@familymatters.expert",
    "city": "San Jose",
    "phone": "408-753-9779",
    "state": "California"
  },
  {
    "name": "Christina Altemueller, Marriage and Family Therapist",
    "website": "https://christinaaltemueller.com/",
    "email": "email@christinaaltemueller.com",
    "city": "San Jose",
    "phone": "408-688-9179",
    "state": "California"
  },
  {
    "name": "Victoria Swenson, Marriage and Family Therapist",
    "website": "https://www.marriagecounselingoflosgatos.com/",
    "email": "vickiswenson@comcast.net",
    "city": "San Jose",
    "phone": "408-375-7320",
    "state": "California"
  },
  {
    "name": "Verna Nelson, Licensed Marriage & Family Therapist",
    "website": "https://vernanelson.com/",
    "email": "verna@vernanelson.com",
    "city": "San Jose",
    "phone": "408-379-7747",
    "state": "California"
  },
  {
    "name": "Licensed Marriage and Family Therapist",
    "website": "http://cottylizama.com/",
    "email": "cotty@cottylizama.com",
    "city": "San Jose",
    "phone": "408-637-6012",
    "state": "California"
  },
  {
    "name": "Grace Tang, Licensed Marriage & Family Therapist",
    "website": "http://www.gracetangcounseling.com/",
    "email": "grace@gracetangcounseling.com",
    "city": "San Jose",
    "phone": "408-912-2808",
    "state": "California"
  },
  {
    "name": "Amrita Singh, LMFT | Adolescent, Marriage & Family Therapy",
    "website": "http://www.amritasinghlmft.com/index.html",
    "email": "amrita@amritasinghlmft.com",
    "city": "San Jose",
    "phone": "408-384-9638",
    "state": "California"
  },
  {
    "name": "Beth Sorensen Agnew, M.A., LMFT, LPCC",
    "website": "https://therapywithbeth.com/",
    "email": "info@therapywithbeth.com",
    "city": "San Jose",
    "phone": "408-834-6985",
    "state": "California"
  },
  {
    "name": "Counseling Recovery",
    "website": "https://www.counselingrecovery.com/",
    "email": "mft@counselingrecovery.com",
    "city": "San Jose",
    "phone": "408-800-5736",
    "state": "California"
  },
  {
    "name": "Stepping Stones Family Counseling Center",
    "website": "http://www.myfamilyharmony.com/",
    "email": "info@myfamilyharmony.com",
    "city": "San Jose",
    "phone": "408-369-1900",
    "state": "California"
  },
  {
    "name": "Joanna Carson-Young, M.A.",
    "website": "https://www.joannacarsonyoung.com/",
    "email": "jcarsonyoung@gmail.com",
    "city": "San Jose",
    "phone": "408-520-1486",
    "state": "California"
  },
  {
    "name": "Feather Stone Counseling",
    "website": "https://www.featherstonecounseling.com/",
    "email": "mfeatherstone@featherstonecounseling.com",
    "city": "San Jose",
    "phone": "408-643-2283",
    "state": "California"
  },
  {
    "name": "Eun Ah Kim",
    "website": "http://www.kimcounseling.com/index.htm",
    "email": "kimcounseling@yahoo.com",
    "city": "San Jose",
    "phone": "408-380-3008",
    "state": "California"
  },
  {
    "name": "Mark Mouro, LMFT",
    "website": "https://markmouro.com/",
    "email": "markmouro.lmft@gmail.com",
    "city": "San Jose",
    "phone": "424-291-2898",
    "state": "California"
  },
  {
    "name": "Bonnie L. Faber, MFT",
    "website": "http://www.bonnielfaber.com/",
    "email": "fabermft@gmail.com",
    "city": "San Jose",
    "phone": "408-836-4110",
    "state": "California"
  },
  {
    "name": "Bernice Lynch MA LMFT",
    "website": "http://bernicelynch.com/index.html",
    "email": "bernice@bernicelynch.com",
    "city": "San Jose",
    "phone": "408-295-2511",
    "state": "California"
  },
  {
    "name": "Tracey W. Nowell, MFT",
    "website": "http://www.traceynowell.com/Home_Page.html",
    "email": "tracey@traceynowell.com",
    "city": "San Jose",
    "phone": "408-647-5638",
    "state": "California"
  },
  {
    "name": "Palo Alto Therapy",
    "website": "https://www.paloaltotherapy.com/",
    "email": "info@paloaltotherapy.com",
    "city": "San Jose",
    "phone": "650-461-9026",
    "state": "California"
  },
  {
    "name": "Counseling Offices of Amy Dunniway",
    "website": "https://www.sctherapist.com/",
    "email": "amdunniway@gmail.com",
    "city": "San Jose",
    "phone": "831-888-6905",
    "state": "California"
  },
  {
    "name": "Teen Therapy Center",
    "website": "https://www.teentherapycentersv.com/",
    "email": "info@teentcsv.com",
    "city": "San Jose",
    "phone": "408-389-3538",
    "state": "California"
  },
  {
    "name": "Jackie Chang Therapy",
    "website": "http://www.jackiechangtherapy.com/",
    "email": "jackie@jackiechangtherapy.com",
    "city": "San Jose",
    "phone": "408-916-3463",
    "state": "California"
  },
  {
    "name": "G Claire Roberts, LCSW",
    "website": "https://gclaireroberts.com/",
    "email": "claire@gclaireroberts.com",
    "city": "San Jose",
    "phone": "650-704-5111",
    "state": "California"
  },
  {
    "name": "Jason Magers, LMFT",
    "website": "https://www.jasonmagersmft.com/",
    "email": "jasonmagersmft@gmail.com",
    "city": "San Jose",
    "phone": "408-800-7305",
    "state": "California"
  },
  {
    "name": "OliveMe Counseling with Joanne Kim, LMFT",
    "website": "https://www.olivemecounseling.com/",
    "email": "jkim@olivemecounseling.com",
    "city": "San Jose",
    "phone": "408-722-3770",
    "state": "California"
  },
  {
    "name": "iMan Therapy Inc",
    "website": "https://imantherapyinc.com/",
    "email": "kamal@imantherapyinc.com",
    "city": "San Jose",
    "phone": "408-345-5293",
    "state": "California"
  },
  {
    "name": "Caron Heimbuck, MA, LMFT",
    "website": "https://www.caronmft.com/index.html",
    "email": "caron@caronmft.com",
    "city": "San Jose",
    "phone": "408-236-3312",
    "state": "California"
  },
  {
    "name": "Kristen Frank, MA, LMFT",
    "website": "http://www.kristenfrank.com/index.html",
    "email": " kristenhfrank@gmail.com",
    "city": "San Jose",
    "phone": "408-293-1028",
    "state": "California"
  },
  {
    "name": "Therese Frey, MA, LMFT",
    "website": "http://theresefrey.com/",
    "email": "freytherapy@gmail.com",
    "city": "San Jose",
    "phone": "408-242-8743",
    "state": "California"
  },
  {
    "name": "Uplift Psychology Group",
    "website": "https://upliftpsychgroup.com/?utm_source=gmb&utm_medium=search&utm_campaign=gmb",
    "email": "upliftpsychgroup@gmail.com",
    "city": "San Jose",
    "phone": "408-404-5443",
    "state": "California"
  },
  {
    "name": "Cristina Freyer, LMFT",
    "website": "http://www.cristinafreyerlmft.com/",
    "email": "cfreyer@cristinafreyerlmft.com",
    "city": "San Jose",
    "phone": "408-475-0459",
    "state": "California"
  },
  {
    "name": "Awake DBT, Inc.",
    "website": "http://www.awakedbt.com/",
    "email": "info@awakedbt.com",
    "city": "San Jose",
    "phone": "408-479-4632",
    "state": "California"
  }
]
